import { createAction } from 'redux-actions';

import Photo from 'constants/photo';
import { AddModifyVehicleProps } from 'store/inventoryItem/addModify/addModifyModels';
import { AppDispatch } from 'store/configureStore';
import { InventoryItemPhotoCreateInput, PhotoStatus, PhotoType } from 'store/shared/api/graph/interfaces/types';
import { Modal, Page } from 'constants/enums/addModifyInventoryItem';
import { convertDataUrlToBinary } from 'utils/fileUtils';
import { convertPhotoDtoToPhoto, readImageAsDataURL } from 'utils/photoUtils';
import {
  createInventoryItem,
  getDeclarationsMeta,
  getDuplicateVins,
  getInventoryItemById,
  inventoryItemPhotoCreate,
  submitAttachCarfax,
  updateInventoryItem,
} from './addModifyApi';
import { getErrors } from 'utils/apiUtils';
import { t } from 'utils/intlUtils';

export const isLoading = createAction('ADD_MODIFY_SET_IS_LOADING');
export const isNotLoading = createAction('ADD_MODIFY_UNSET_IS_LOADING');
export const isUpdating = createAction('ADD_MODIFY_SET_IS_UPDATING');
export const addModifySet = createAction('ADD_MODIFY_SET');
export const addModifyClear = createAction('ADD_MODIFY_CLEAR');
export const addModifySetVehicle = createAction('ADD_MODIFY_SET_VEHICLE');
export const addModifySetAttachCarfax = createAction('ADD_MODIFY_SET_ATTACH_CARFAX');
export const addModifyAddPhoto = createAction('ADD_MODIFY_ADD_PHOTO');
export const addModifyPhotosError = createAction('ADD_MODIFY_PHOTOS_ERROR');
export const addModifySetError = createAction('ADD_MODIFY_ERROR');
export const addModifyClearError = createAction('ADD_MODIFY_CLEAR_ERROR');
export const setDuplicateVins = createAction('ADD_MODIFY_SET_DUPLICATE_VINS');
export const setDeclarations = createAction('ADD_MODIFY_SET_DECLARATIONS');
export const setPage = createAction('ADD_MODIFY_SET_PAGE');
export const setPageImplicitly = createAction('ADD_MODIFY_SET_PAGE_IMPLICITLY');
export const setModal = createAction('ADD_MODIFY_SET_MODAL');
export const setOverlay = createAction('ADD_MODIFY_SET_OVERLAY');
export const setCountryCode = createAction('ADD_MODIFY_SET_COUNTRY_CODE');
export const setPhotoRemainingUploads = createAction('ADD_MODIFY_SET_PHOTO_REMAINING_UPLOADS');
export const pinVehicleDetails = createAction('ADD_MODIFY_PIN_VEHICLE_DETAILS');

export interface LegacyInventoryPhotoOptions {
  formDataArray: FormData[];
  type: PhotoType;
}

export interface UploadInventoryPhotoOption extends InventoryItemPhotoCreateInput {
  /** Photo file to be uploaded. */
  photoFile: File;
}

export type UploadInventoryPhotoOptions = UploadInventoryPhotoOption[];

export const processCreateInventoryItem = (dispatch, { switchPage = false, ...options }) => {
  dispatch(isLoading());
  return createInventoryItem(options as any)
    .then((response) => dispatch(addModifySet({ results: response?.data?.data?.inventoryItemCreate, switchPage })))
    .then(() => {
      dispatch(pinVehicleDetails());
      dispatch(setPageImplicitly({ switchPage }));
    })
    .catch((err) => dispatch(addModifySetError(getErrors(err))));
};

export const processUpdateInventoryItem = (dispatch, options) => {
  dispatch(isUpdating());
  return updateInventoryItem(options)
    .then((response) => dispatch(addModifySet({ results: response?.data?.data?.inventoryItemUpdate })))
    .catch((err) => dispatch(addModifySetError(getErrors(err))));
};

export const processGetInventoryItemById = (dispatch, options) => {
  dispatch(isUpdating());
  return getInventoryItemById(options)
    .then((response) => {
      dispatch(addModifySet({ results: response?.data?.data?.inventoryItemUpdate }));
      dispatch(setPage(Page.VEHICLE_FORM));
    })
    .catch((err) => {
      const errors = getErrors(err);
      dispatch(setModal(Modal.ERROR_DIALOG));
      dispatch(addModifySetError(errors));
    });
};

export const processSubmitVinOverlay = (dispatch: AppDispatch, options: AddModifyVehicleProps) => {
  return getDuplicateVins(options.vin)
    .then((response) => {
      const data = response?.data?.data;
      if (!data) {
        return { duplicates: [] };
      }

      const duplicates = Object.keys(data)
        .filter((key) => data[key].edges.length)
        .map((key) => {
          return data[key].edges.map((edge) => ({
            section: key,
            data: edge.node.inventoryItem
              ? { ...edge.node.inventoryItem, id: edge.node.id, created: edge.node.created }
              : edge.node,
          }));
        })
        .flat()
        .sort((itemA, itemB) => {
          return itemA.data.created && itemB.data.created
            ? new Date(itemB.data.created).getTime() - new Date(itemA.data.created).getTime()
            : 0;
        })
        .slice(0, 4);

      return { duplicates };
    })
    .then((response) => {
      const { duplicates } = response;

      dispatch(setDuplicateVins(duplicates));
      if (!duplicates.length) {
        processCreateInventoryItem(dispatch, { ...options, switchPage: true });
      } else {
        dispatch(setPage(Page.VIN_OVERLAY_DUPLICATE));
      }
    });
};

export const processSubmitAttachCarfax = (dispatch, options) => {
  dispatch(isUpdating());
  return submitAttachCarfax(options)
    .then((response) =>
      dispatch(addModifySetAttachCarfax(response?.data?.data?.vehicleCarfaxCanadaAttachReport?.conditionReport))
    )
    .catch((err) => {
      const errors = err?.response?.data?.errors;
      dispatch(addModifySetError(errors));
    });
};

export const processGetDeclarationsMeta = (dispatch) => {
  return getDeclarationsMeta().then((response) => {
    const declarationsMeta = response?.data?.data?.inventoryItemMetaData?.vehicleMetaData;
    return dispatch(setDeclarations(declarationsMeta));
  });
};

const uploadSingleInventoryPhoto = async ({ photoFile, ...props }: UploadInventoryPhotoOption): Promise<Photo> => {
  // Read the photo file
  const photoDataURL = await readImageAsDataURL(photoFile);
  if (!photoDataURL) {
    return Promise.reject(new Error(t('unable_to_load_file_x', [photoFile.name])));
  }

  const photoBinary = convertDataUrlToBinary(photoDataURL, props.contentType);
  // Get the pre-signed URL
  const response = await inventoryItemPhotoCreate(props);
  const photoDTOs = response?.data?.data?.inventoryItemPhotoCreate?.photoDTOs;
  // Find the photo DTO added by `inventoryItemPhotoCreate`
  const createdPhotoDTO = photoDTOs?.filter(Boolean)?.find((photoDTO) => {
    return (
      photoDTO.shotCode === props.shotCode &&
      photoDTO.type === props.photoType &&
      photoDTO.status === PhotoStatus.INIT &&
      photoDTO.uploadUrl
    );
  });

  if (createdPhotoDTO?.uploadUrl) {
    // Upload photo to S3
    const result = await fetch(createdPhotoDTO.uploadUrl, {
      body: photoBinary,
      method: 'PUT',
    });
    // Error handling
    if (result.ok) {
      const createdPhoto = convertPhotoDtoToPhoto(createdPhotoDTO);
      createdPhoto.status = PhotoStatus.UPLOADED;
      return Promise.resolve(createdPhoto);
    }
  }

  return Promise.reject(new Error(t('upload_photo_x_error_message', [photoFile.name])));
};

export const processUploadInventoryPhoto = (dispatch, options: UploadInventoryPhotoOption[] = []) => {
  const getRemainingUploads = (index) => options.length - index - 1;
  dispatch(addModifyClearError());
  // Set remaining uploads to the number of photos to be uploaded.
  dispatch(setPhotoRemainingUploads({ remainingUploads: options.length, uploadPhotoType: options[0]?.photoType }));
  // Iterates over each image object in the options array.
  // Uploads photos sequentially or throws error, dispatches reducer before moving onto next image object
  options.reduce(
    (promise: Promise<void>, option: UploadInventoryPhotoOption, index: number) =>
      promise
        .then(() => {
          const remainingUploads = getRemainingUploads(index);
          return uploadSingleInventoryPhoto(option)
            .then((response) => {
              const photo = {
                photo: response,
                remainingUploads,
                uploadPhotoType: option.photoType,
              };
              dispatch(addModifyAddPhoto(photo));
            })
            .catch((error) => {
              const _error = {
                errorMessage: error?.response ?? error,
                remainingUploads,
                uploadPhotoType: option.photoType,
              };
              throw _error;
            });
        })
        .catch((error) => dispatch(addModifyPhotosError(error?.errorMessage))),
    Promise.resolve()
  );
};
