// extracted by mini-css-extract-plugin
export default {"colorBlack":"#1d2126","colorBlackTransparent":"#1d212680","colorBlue":"#378ccb","colorBlueDark":"#0c65a7","colorBlueLighter":"#ebf4fa","colorGrayDark":"#5b656d","colorGrayDarker":"#313d48","colorGrayDarkest":"#24292f","colorGrayLight":"#adb2b6","colorGrayLighter":"#d6d8da","colorGrayLightest":"#ebeced","colorGreen":"#26b899","colorGreenLightest":"#e9f8f5","colorRed":"#e76f67","colorRedLightest":"#fdf1f0","colorText":"#24292f","colorWhite":"#fff","colorYellow":"#f6b551","fontWeightThin":"200","fontWeightRegular":"400","fontWeightSemiBold":"600","fontWeightBold":"700","fontSize10":".625rem","zIndexNeg1":"-1","zIndex0":"0","zIndex1":"1","zIndex2":"2","zIndex3":"3","zIndex4":"4","zIndex5":"5","selectLocation":"selectLocation_d8db61e","headerText":"headerText_eaf5550","reserveNotification":"reserveNotification_d4b7783","inputContainer":"inputContainer_afa7b0c","dollarSign":"dollarSign_e41dc14","startingPriceContainer":"startingPriceContainer_c16ebad","dropdown":"dropdown_fb3ae4d","placeholderDropdown":"placeholderDropdown_b5139c7","subTitle":"subTitle_f3d88e1","radioButtons":"radioButtons_cac0b8a","radioButtonsContainer":"radioButtonsContainer_ca7e1eb","radioButtonsAuction":"radioButtonsAuction_e36161f","fetchingAuctionTimeSlotSpinner":"fetchingAuctionTimeSlotSpinner_bca590e","footer":"footer_a8cd04e","button":"button_ced1e76","arrowSprite":"arrowSprite_d40fd04","spinnerStyle":"spinnerStyle_f972847","asIsContainer":"asIsContainer_c675746","asIsCause":"asIsCause_eabaf0d","asIsRadioLabel":"asIsRadioLabel_f5691fc","infoContainer":"infoContainer_f9d5371","infoIcon":"infoIcon_aba239b","infoContent":"infoContent_ef57c3f"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"colorBlack\":\"#1d2126\",\"colorBlackTransparent\":\"#1d212680\",\"colorBlue\":\"#378ccb\",\"colorBlueDark\":\"#0c65a7\",\"colorBlueLighter\":\"#ebf4fa\",\"colorGrayDark\":\"#5b656d\",\"colorGrayDarker\":\"#313d48\",\"colorGrayDarkest\":\"#24292f\",\"colorGrayLight\":\"#adb2b6\",\"colorGrayLighter\":\"#d6d8da\",\"colorGrayLightest\":\"#ebeced\",\"colorGreen\":\"#26b899\",\"colorGreenLightest\":\"#e9f8f5\",\"colorRed\":\"#e76f67\",\"colorRedLightest\":\"#fdf1f0\",\"colorText\":\"#24292f\",\"colorWhite\":\"#fff\",\"colorYellow\":\"#f6b551\",\"fontWeightThin\":\"200\",\"fontWeightRegular\":\"400\",\"fontWeightSemiBold\":\"600\",\"fontWeightBold\":\"700\",\"fontSize10\":\".625rem\",\"zIndexNeg1\":\"-1\",\"zIndex0\":\"0\",\"zIndex1\":\"1\",\"zIndex2\":\"2\",\"zIndex3\":\"3\",\"zIndex4\":\"4\",\"zIndex5\":\"5\",\"selectLocation\":\"selectLocation_d8db61e\",\"headerText\":\"headerText_eaf5550\",\"reserveNotification\":\"reserveNotification_d4b7783\",\"inputContainer\":\"inputContainer_afa7b0c\",\"dollarSign\":\"dollarSign_e41dc14\",\"startingPriceContainer\":\"startingPriceContainer_c16ebad\",\"dropdown\":\"dropdown_fb3ae4d\",\"placeholderDropdown\":\"placeholderDropdown_b5139c7\",\"subTitle\":\"subTitle_f3d88e1\",\"radioButtons\":\"radioButtons_cac0b8a\",\"radioButtonsContainer\":\"radioButtonsContainer_ca7e1eb\",\"radioButtonsAuction\":\"radioButtonsAuction_e36161f\",\"fetchingAuctionTimeSlotSpinner\":\"fetchingAuctionTimeSlotSpinner_bca590e\",\"footer\":\"footer_a8cd04e\",\"button\":\"button_ced1e76\",\"arrowSprite\":\"arrowSprite_d40fd04\",\"spinnerStyle\":\"spinnerStyle_f972847\",\"asIsContainer\":\"asIsContainer_c675746\",\"asIsCause\":\"asIsCause_eabaf0d\",\"asIsRadioLabel\":\"asIsRadioLabel_f5691fc\",\"infoContainer\":\"infoContainer_f9d5371\",\"infoIcon\":\"infoIcon_aba239b\",\"infoContent\":\"infoContent_ef57c3f\"}";
        // 1726588243585
        var cssReload = require("../../../../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  