// extracted by mini-css-extract-plugin
export default {"colorBlack":"#1d2126","colorBlackTransparent":"#1d212680","colorBlue":"#378ccb","colorBlueDark":"#0c65a7","colorBlueLighter":"#ebf4fa","colorGrayDark":"#5b656d","colorGrayDarker":"#313d48","colorGrayDarkest":"#24292f","colorGrayLight":"#adb2b6","colorGrayLighter":"#d6d8da","colorGrayLightest":"#ebeced","colorGreen":"#26b899","colorGreenLightest":"#e9f8f5","colorRed":"#e76f67","colorRedLightest":"#fdf1f0","colorText":"#24292f","colorWhite":"#fff","colorYellow":"#f6b551","fontWeightThin":"200","fontWeightRegular":"400","fontWeightSemiBold":"600","fontWeightBold":"700","fontSize10":".625rem","zIndexNeg1":"-1","zIndex0":"0","zIndex1":"1","zIndex2":"2","zIndex3":"3","zIndex4":"4","zIndex5":"5","type-body-1":"type-body-1_c858cc8","type-body-2":"type-body-2_e42a29b","type-body-3":"type-body-3_d52c93d","type-body-4":"type-body-4_a87bdd2","type-section-title":"type-section-title_ba1b9e4","type-notes":"type-notes_ecd645c","type-item-title":"type-item-title_cbd6b3f","type-item-subtitle":"type-item-subtitle_b7f877f","type-subtitle":"type-subtitle_ed2ebeb","type-title":"type-title_ef4aad5","facetGroups":"facetGroups_cce6875","titleContainer":"titleContainer_de62a8a","title":"title_d76746f","heading":"heading_c269ec9","spacer":"spacer_e3b19e0","showMyUnitsSwitch":"showMyUnitsSwitch_c038bd4","radio":"radio_d767a59","selected":"selected_c8393c3","dateRangeFacetGroup":"dateRangeFacetGroup_cd99d4a","valueLabel":"valueLabel_a5b55e8","dateRanges":"dateRanges_dc8e1f6","bulge":"bulge_f1cdb94"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"colorBlack\":\"#1d2126\",\"colorBlackTransparent\":\"#1d212680\",\"colorBlue\":\"#378ccb\",\"colorBlueDark\":\"#0c65a7\",\"colorBlueLighter\":\"#ebf4fa\",\"colorGrayDark\":\"#5b656d\",\"colorGrayDarker\":\"#313d48\",\"colorGrayDarkest\":\"#24292f\",\"colorGrayLight\":\"#adb2b6\",\"colorGrayLighter\":\"#d6d8da\",\"colorGrayLightest\":\"#ebeced\",\"colorGreen\":\"#26b899\",\"colorGreenLightest\":\"#e9f8f5\",\"colorRed\":\"#e76f67\",\"colorRedLightest\":\"#fdf1f0\",\"colorText\":\"#24292f\",\"colorWhite\":\"#fff\",\"colorYellow\":\"#f6b551\",\"fontWeightThin\":\"200\",\"fontWeightRegular\":\"400\",\"fontWeightSemiBold\":\"600\",\"fontWeightBold\":\"700\",\"fontSize10\":\".625rem\",\"zIndexNeg1\":\"-1\",\"zIndex0\":\"0\",\"zIndex1\":\"1\",\"zIndex2\":\"2\",\"zIndex3\":\"3\",\"zIndex4\":\"4\",\"zIndex5\":\"5\",\"type-body-1\":\"type-body-1_c858cc8\",\"type-body-2\":\"type-body-2_e42a29b\",\"type-body-3\":\"type-body-3_d52c93d\",\"type-body-4\":\"type-body-4_a87bdd2\",\"type-section-title\":\"type-section-title_ba1b9e4\",\"type-notes\":\"type-notes_ecd645c\",\"type-item-title\":\"type-item-title_cbd6b3f\",\"type-item-subtitle\":\"type-item-subtitle_b7f877f\",\"type-subtitle\":\"type-subtitle_ed2ebeb\",\"type-title\":\"type-title_ef4aad5\",\"facetGroups\":\"facetGroups_cce6875\",\"titleContainer\":\"titleContainer_de62a8a\",\"title\":\"title_d76746f\",\"heading\":\"heading_c269ec9\",\"spacer\":\"spacer_e3b19e0\",\"showMyUnitsSwitch\":\"showMyUnitsSwitch_c038bd4\",\"radio\":\"radio_d767a59\",\"selected\":\"selected_c8393c3\",\"dateRangeFacetGroup\":\"dateRangeFacetGroup_cd99d4a\",\"valueLabel\":\"valueLabel_a5b55e8\",\"dateRanges\":\"dateRanges_dc8e1f6\",\"bulge\":\"bulge_f1cdb94\"}";
        // 1726588230042
        var cssReload = require("../../../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  